import {
	Tooltip as TooltipMui,
	TooltipProps as TooltipPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type TooltipProps = TooltipPropsMui;

const TooltipStyled = styled(TooltipMui)<TooltipProps>``;

const Tooltip: React.FC<TooltipProps> = props => {
	return <TooltipStyled {...props}>{props.children}</TooltipStyled>;
};

export default Tooltip;
