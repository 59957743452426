import useDevice from 'hooks/DesktopDevice.hook';
import { BoxOverflow, Stack } from 'styles';
import FormContactUs from './FormContactUs.component';

const ContactUs = () => {
	const { isDesktop } = useDevice();

	return (
		<Stack
			height="100%"
			direction="row"
			gap={isDesktop ? '268px' : '63px'}
			pb={isDesktop ? '60px' : '30px'}
			pt={isDesktop ? '36.5px' : '26px'}
		>
			<BoxOverflow variant="y">
				<FormContactUs />
			</BoxOverflow>
		</Stack>
	);
};

export default ContactUs;
