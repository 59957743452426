import { FC, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	DatePicker,
	FormControl,
	Stack,
	StackRadio,
	Typography,
} from 'styles';
import { IClaim, IPrescription, PRESCRIPTION_DEFAULT } from 'types/Claim';

import SelectGeneric from 'components/commons/form/SelectGeneric';
import dayjs, { Dayjs } from 'dayjs';
import { getPrescriptions } from 'feature/claims/claimSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { PageProps } from 'types/newClaimPageType';
import { TRANSLATION_KEYS } from 'utils/constants';
import Prescription from './components/Prescription';

type IDoctorType = 'Optician' | 'Optometrist' | 'Ophthalmologist';

const Page1: FC<PageProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const dispatch = useDispatch<AppDispatch>();
	const { formData, setFormData, submitPage } = props;
	const { doctorTypes } = useSelector((store: RootState) => store.claim);

	const [checkFields, setCheckFields] = useState<boolean>(false);
	const [newPresCompleted, setNewPresCompleted] = useState<boolean>(false);
	const [oldPresCompleted, setOldPresCompleted] = useState<boolean>(false);
	const [isPrescriptionChanged, setIsPrescriptionChanged] = useState<boolean>(
		formData.rxChangedWithoutOldPrescription || false,
	);

	const showOldPrescription =
		formData.rxChangeAttestation === 1 && !isPrescriptionChanged;

	const [doctorType, setDoctorType] = useState<IDoctorType | 'reset'>(
		(formData.newPrescriptionContent?.doctorTypology as IDoctorType) || 'reset',
	);
	const [prescriptionDate, setPrescriptionDate] = useState<
		Dayjs | null | string
	>(formData.newPrescriptionContent?.prescriptionDate || null);
	const [errorDate, setErrorDate] = useState<boolean>(false);
	const [errorDoctorType, setErrorDoctorType] = useState<boolean>(false);
	const [errorDateMessage, setErrorDateMessage] = useState<string>(
		'prescriptionDateError',
	);

	useEffect(() => {
		scrollWithError();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorDate, errorDoctorType]);

	const scrollWithError = () => {
		if (
			errorDate ||
			errorDoctorType ||
			(showOldPrescription && !oldPresCompleted)
		) {
			const box = document.getElementById('page1-scroll');
			if (box) {
				if (formData.rxChangeAttestation === 0) {
					box.scrollTo({ top: 150, behavior: 'smooth' });
				} else if (formData.rxChangeAttestation === 1) {
					const topValue = isPrescriptionChanged
						? 220
						: !oldPresCompleted
						? 220
						: 710;
					box.scrollTo({ top: topValue, behavior: 'smooth' });
				}
			}
		}
	};

	const handleChangeRadio = (value: string) => {
		if (Number(value) === 1) {
			dispatch(getPrescriptions({}));
		}
		setErrorDate(false);
		setErrorDoctorType(false);
		setIsPrescriptionChanged(false);
		setFormData((prev: IClaim) => ({
			...prev,
			newPrescriptionContent: prev.newPrescriptionContent || {
				...PRESCRIPTION_DEFAULT,
			},
			oldPrescriptionContent:
				Number(value) === 1
					? {
							...PRESCRIPTION_DEFAULT,
					  }
					: undefined,
			rxChangedWithoutOldPrescription: Number(value) === 1 ? false : null,
			rxChangeAttestation: Number(value) as 0 | 1,
		}));
	};

	const handleSubmit = () => {
		scrollWithError();

		//controllo per vedere se si tratta di una data futura
		const isNotValidDate = dayjs(prescriptionDate).isAfter(dayjs());
		if (
			newPresCompleted &&
			doctorType &&
			prescriptionDate &&
			!isNotValidDate &&
			(oldPresCompleted || !showOldPrescription)
		) {
			setCheckFields(false);
			submitPage!();
		} else {
			if (!doctorType || doctorType === 'reset') {
				setErrorDoctorType(true);
			}
			//se la data non è stata inserita
			if (!prescriptionDate) {
				setErrorDate(true);
				setErrorDateMessage('prescriptionDateError');
			}

			//se la data inserita è futura
			if (isNotValidDate) {
				setErrorDate(true);
				setErrorDateMessage('prescriptionDateErrorAfter');
			}
			setCheckFields(true);
		}
	};

	return (
		<>
			<Stack gap="10px">
				<Typography variant="title6">
					{tClientLabels('newClaim.selectedPage.sectionTitile.enterPrescription')}
				</Typography>
				<StackRadio
					onChange={(_, value) => handleChangeRadio(value)}
					value={formData.rxChangeAttestation ?? null}
					label={tClientLabels('newClaim.prescription.hasOldPrescriptionQ')}
					options={[
						{
							label: tClientLabels(`newClaim.prescription.hasOldPrescriptionA.yes`),
							value: 1,
						},
						{
							label: tClientLabels(`newClaim.prescription.hasOldPrescriptionA.no`),
							value: 0,
						},
					]}
					variant="transparent"
				/>
			</Stack>

			{formData.rxChangeAttestation === 1 && (
				<Stack sx={{ mt: '20px', ml: '-6px' }}>
					<Checkbox
						label={tClientLabels('newClaim.prescription.prescriptionChanged')}
						checked={isPrescriptionChanged}
						onChange={(_, value) => {
							setIsPrescriptionChanged(value);
							setFormData((prev: IClaim) => ({
								...prev,
								oldPrescriptionContent: value
									? undefined
									: (prev.oldPrescriptionContent as IPrescription),
								rxChangedWithoutOldPrescription: value,
							}));
						}}
					/>
				</Stack>
			)}

			<Stack mt="20px" gap="20px">
				{(formData.rxChangeAttestation === 0 ||
					formData.rxChangeAttestation === 1) && (
					<>
						<Typography variant="body" mb={showOldPrescription ? '10px' : 0}>
							{tClientLabels('newClaim.prescription.fillIn')}
						</Typography>
						{showOldPrescription && (
							<Prescription
								presFilled={oldPresCompleted}
								key="old"
								label="newClaim.prescription.oldPrescriptionContent"
								data={formData.oldPrescriptionContent!}
								isOldPrescription={true}
								setData={(value: IPrescription) => {
									setFormData((prev: IClaim) => ({
										...prev,
										oldPrescriptionContent: value,
									}));
								}}
								checkFields={checkFields}
								setRequiredFilled={setOldPresCompleted}
							/>
						)}
						<Typography
							variant="bodyLink"
							mt={formData.rxChangeAttestation === 1 ? '10px' : 0}
							mb={showOldPrescription ? '-20px' : '-10px'}
						>
							{tClientLabels(
								formData.rxChangeAttestation === 1
									? 'newClaim.prescription.newPrescriptionContent'
									: 'newClaim.prescription.Prescription',
							)}
						</Typography>
						<Stack
							flexDirection={'row'}
							mt={
								formData.rxChangeAttestation === 0 ||
								(formData.rxChangeAttestation === 1 && isPrescriptionChanged)
									? 0
									: 1.25
							}
							mb={'-10px'}
						>
							<Stack>
								<SelectGeneric
									action={(payload: string) => {
										setDoctorType(payload as IDoctorType);
										setFormData((prev: IClaim) => ({
											...prev,
											newPrescriptionContent: {
												...prev.newPrescriptionContent,
												doctorTypology: payload,
											} as IPrescription,
										}));
										setErrorDoctorType(false);
									}}
									items={doctorTypes.map(type => ({
										value: type.name,
										text: tDbLabels(type.label),
									}))}
									name={'doctorType'}
									sx={{ width: { sm: 340, lg: 368 } }}
									initialValue={doctorType}
									error={errorDoctorType}
								/>
								{errorDoctorType && (
									<Typography variant="bodyLink" color={palette.error.main}>
										{tClientLabels(`newClaim.prescription.doctorTypeError`)}
									</Typography>
								)}
							</Stack>
							<FormControl sx={{ width: { sm: 340, lg: 368 }, marginLeft: 2 }}>
								<DatePicker
									variant="margin"
									label={tClientLabels('newClaim.prescription.dateOfPrescription')}
									value={dayjs(prescriptionDate)}
									onChange={value => {
										setErrorDate(false);
										setPrescriptionDate(value);
										setFormData((prev: IClaim) => ({
											...prev,
											newPrescriptionContent: {
												...prev.newPrescriptionContent,
												prescriptionDate: value?.format('YYYY-MM-DDTHH:mm[Z]'),
											} as IPrescription,
										}));
									}}
									error={errorDate}
								/>
								{errorDate && (
									<Typography variant="bodyLink" color={palette.error.main}>
										{tClientLabels(`newClaim.prescription.${errorDateMessage}`)}
									</Typography>
								)}
							</FormControl>
						</Stack>
						<Prescription
							isOldPrescription={false}
							presFilled={newPresCompleted}
							key="new"
							label={''}
							data={formData.newPrescriptionContent!}
							setData={(value: IPrescription) => {
								setFormData((prev: IClaim) => ({
									...prev,
									newPrescriptionContent: value,
								}));
							}}
							checkFields={checkFields}
							setRequiredFilled={setNewPresCompleted}
						/>
						<Stack sx={{ mt: '10px', left: '-9px' }}>
							<Checkbox
								label={tClientLabels('newClaim.prescription.prescriptionConsent')}
								checked={!!formData.prescriptionConsent}
								onChange={(_, value) => {
									setFormData((prev: IClaim) => ({
										...prev,
										prescriptionConsent: value,
									}));
								}}
							/>
						</Stack>
					</>
				)}
			</Stack>
			<Box alignItems="start" mt="30px">
				<Button
					disabled={!formData.prescriptionConsent}
					variant="contained"
					sx={{ mt: '10px' }}
					onClick={handleSubmit}
				>
					{tClientLabels('newClaim.selectedPage.proceed')}
				</Button>
			</Box>
		</>
	);
};

export default Page1;
