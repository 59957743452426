import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

declare global {
	namespace JSX {
		interface IntrinsicElements {
			'lxl-app-contact-form': React.DetailedHTMLProps<any, any>;
		}
	}
}

function FormContactUs() {
	const { profile } = useSelector((store: RootState) => store.user);
	const { locationFields } = useSelector(
		(store: RootState) => store.locationInformation,
	);

	let payload;
	if (locationFields.email) {
		payload = {
			name: profile?.name.split(' ')[0],
			surname: profile?.name.split(' ')[1],
			email: locationFields.email,
			store: profile?.location,
			telephone: locationFields.phoneNumber,
		};
	}

	return payload ? (
		<lxl-app-contact-form
			id="form"
			brand="EM"
			country="IT"
			locale="it"
			payload={JSON.stringify(payload)}
		></lxl-app-contact-form>
	) : (
		<></>
	);
}

export default FormContactUs;
