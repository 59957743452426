import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteDocument, uploadDocument } from 'feature/upload/uploadSlice';
import { IClaim, IClaimState, TStatusCalculation } from 'types/Claim';
import {
	calculateEstimatesThunk,
	getClaimDetailsThunk,
	getDoctorTypesThunk,
	getPrescriptionsThunk,
	saveClaimThunk,
	submitClaimThunk,
} from './ClaimThunk';

export const getClaimDetails = createAsyncThunk(
	'claim/getClaimDetails',
	getClaimDetailsThunk,
);

export const getPrescriptions = createAsyncThunk(
	'claim/getPrescriptions',
	getPrescriptionsThunk,
);

export const saveClaim = createAsyncThunk('claim/saveClaim', saveClaimThunk);

export const submitClaim = createAsyncThunk(
	'claim/submitClaim',
	submitClaimThunk,
);

export const calculateEstimates = createAsyncThunk(
	'claim/calculateEstimates',
	calculateEstimatesThunk,
);

export const getDoctorTypes = createAsyncThunk(
	'claim/getDoctorTypes',
	getDoctorTypesThunk,
);

const initialState: IClaimState = {
	claimData: {
		claimId: undefined,
		externalClaimId: '',
		beneficiaryMemberId: '',
		memberId: '',
		beneficiarySubscriptionId: 0,
		memberSubscriptionId: 0,
		providerId: '',
		claimDate: '',
		step: 0,
		// page 1
		exam: 0,
		materials: 0,
		lensType: undefined,
		rxChangedWithoutOldPrescription: null,
		//page 2
		newPrescriptionContent: undefined,
		oldPrescriptionContent: undefined,
		prescriptionConsent: false,
		//page 3
		amount: {
			//basically everything is passed by calculate. only serviceid and retail amount is not
			components: [],
			total: {
				retailAmount: '',
				total: undefined,
				contractualAdjustmentAmount: undefined,
				memberPayAmount: undefined,
				planPayAmount: undefined,
				serviceName: undefined,
				serviceId: null,
				discountedAmount: undefined,
				assessment: undefined,
				allowablePlanPay: undefined,
				coPay: undefined,
				coInsurance: undefined,
				disallowedAmount: undefined,
				decliningBalance: undefined,
				claimCostReduction: undefined,
				inNetworkSave: undefined,
			},
		},
		rxChangeAttestation: undefined,
		rxAttestation: undefined,
		receipt: undefined,
		prescription: undefined,
		uploadConsent: false,
	},
	prescriptionSelectedFromStorage: false,
	errorCode: 0,
	errorMessage: '',
	triggerRefresh: true,
	calculated: false,
	redirect: false,
	doctorTypes: [],
	statusCalculation: 'beforeCalculation',
};

const claimSlice = createSlice({
	name: 'claim',
	initialState,
	reducers: {
		resetClaim: () => initialState,
		setCalculated: (state, action) => ({ ...state, calculated: action.payload }),
		createClaim: (state, action) => ({
			...initialState,
			claimData: { ...initialState.claimData, ...action.payload },
		}),
		onChangeClaim: (state, action: PayloadAction<(arg0: IClaim) => IClaim>) => {
			return { ...state, claimData: action.payload(state.claimData) };
		},
		setSelectedPage: (state, action) => {
			return { ...state, claimData: { ...state.claimData, step: action.payload } };
		},
		setClaimPrescription: (state, action) => {
			const prescriptionId = action.payload.id;
			const prescriptionFileName = action.payload.fileName;

			return {
				...state,
				claimData: {
					...state.claimData,
					prescription: {
						id: prescriptionId,
						fileName: prescriptionFileName,
					},
				},
				prescriptionSelectedFromStorage: true,
			};
		},
		resetPrescriptionSelectedFromStorage: state => {
			return {
				...state,
				claimData: {
					...state.claimData,
					prescription: undefined,
				},
				prescriptionSelectedFromStorage: false,
			};
		},
		setStatusCalculation: (
			state,
			{ payload }: PayloadAction<TStatusCalculation>,
		) => {
			return {
				...state,
				statusCalculation: payload,
			};
		},
	},
	extraReducers: builder => {
		builder

			.addCase(getClaimDetails.fulfilled, (state, { payload }) => {
				return {
					...state,
					claimData: {
						...payload,
						step: [0, 1, 2].includes(payload.step) ? payload.step : 0, // backwards compatibility
						amount: {
							...payload.amount,
							components: sortServices(payload.amount.components),
						},
					},
					triggerRefresh: false,
				};
			})
			.addCase(saveClaim.fulfilled, (state, { meta, payload }) => {
				return {
					...state,
					claimData: {
						...state.claimData,
						step: payload.step,
						claimId: payload.claimId,
					},
					triggerRefresh: true,
					redirect: meta.arg.redirect,
				};
			})
			.addCase(getPrescriptions.fulfilled, (state, { payload }) => {
				// prescription 0 is the current one. 1 is the last one
				const oldPrescription = payload.prescriptionList[0]?.content;
				if (oldPrescription) {
					return {
						...state,
						claimData: {
							...state.claimData,
							oldPrescriptionContent: oldPrescription,
						},
					};
				}
			})
			.addCase(submitClaim.fulfilled, (state, _) => {
				return { ...state, redirect: true };
			})
			.addCase(calculateEstimates.fulfilled, (state, { payload }) => {
				return {
					...state,
					calculated: true,
					claimData: {
						...state.claimData,
						amount: { ...payload, components: sortServices(payload.components) },
					},
				};
			})
			.addCase(uploadDocument.fulfilled, (state, action) => {
				const {
					meta: {
						arg: { docType },
					},
				} = action;
				const { id, fileName } = action.payload;
				state.claimData[docType] = {
					id,
					fileName,
				};
				state.prescriptionSelectedFromStorage = false;
			})
			.addCase(deleteDocument.fulfilled, (state, action) => {
				const {
					meta: {
						arg: { docType },
					},
				} = action;
				state.claimData[docType] = undefined;
				state.prescriptionSelectedFromStorage = false;
			})
			.addCase(getDoctorTypes.fulfilled, (state, { payload }) => {
				state.doctorTypes = payload;
			});
	},
});

const sortServices = (
	components: IClaim['amount']['components'] | undefined,
): IClaim['amount']['components'] => {
	const sortOreder = [3, 2, 1, 5];
	return components
		? components.sort(
				(a, b) =>
					sortOreder.indexOf(a.serviceId!) - sortOreder.indexOf(b.serviceId!),
		  )
		: [];
};

export const {
	resetClaim,
	createClaim,
	onChangeClaim,
	setCalculated,
	setSelectedPage,
	setClaimPrescription,
	resetPrescriptionSelectedFromStorage,
	setStatusCalculation,
} = claimSlice.actions;

export default claimSlice.reducer;
